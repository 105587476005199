import React, { useEffect } from 'react';
import Container from '@/components/ui/Container/Container.tsx';
import { Form, Formik, FormikHelpers } from 'formik';
import FormikInput from '@/components/Formik/FormikInput.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import { resetPassword } from '@/api/auth.ts';
import styles from './ResetPassword.module.scss';
import routes from '@/constants/routes.ts';
import { useLocation, useNavigate } from 'react-router';
import { resetPasswordSchema } from '@/validations/user.ts';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import GlobalError from '@/components/FormikErrors/GlobalError.tsx';
import { useWhoamiStore } from '@/store/useWhoamiStore';
import FormikPasswordInput from '@/components/Formik/FormikPasswordInput.tsx';

interface LoginFormValues {
	email: string;
	password: string;
	confirmPassword: string;
	token: string;
}

const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

const ResetPassword: React.FC = () => {
	const navigate = useNavigate();
	const query = useQuery();
	const token = query.get('token');
	const email = query.get('email');
	const { setIsPasswordReset } = useWhoamiStore(state => state);

	useEffect(() => {
		if (!token) {
			navigate(routes.dashboard);
		}
	}, [token, email, navigate]);

	const handleSubmit = async (values: LoginFormValues, formikHelpers: FormikHelpers<LoginFormValues>) => {
		try {
			await resetPassword(values.email, values.password, values.token);
			navigate(routes.login);
			setIsPasswordReset();
		} catch (error) {
			handleResponseErrors(error, formikHelpers);
		}
	};
	return (
		<MainLayout>
			<Container>
				<div className={styles.resetContainer}>
					<h1>Reset your password</h1>
					<Formik
						initialValues={{ email: email || '', password: '', confirmPassword: '', token: token || '' }}
						validationSchema={resetPasswordSchema}
						onSubmit={handleSubmit}
					>
						{({ isSubmitting }) => (
							<Form>
								<GlobalError />
								<div>
									<FormikInput name="email" label="Email*" type="email" className={styles.formikInput} />
									<FormikPasswordInput name="password" label="Password*" type="password" className={styles.formikInput} />
									<FormikPasswordInput
										name="confirmPassword"
										label="Confirm Password*"
										type="password"
										className={styles.formikInput}
									/>
									<Button
										type="submit"
										color="green"
										size="large"
										loading={isSubmitting}
										disabled={isSubmitting}
										className={styles.submitButton}
									>
										Save new password
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Container>
		</MainLayout>
	);
};

export default ResetPassword;
