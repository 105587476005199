import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import MainLayout from '@/layouts/MainLayout/MainLayout.tsx';
import styles from './Login.module.scss';
import FormikInput from '@/components/Formik/FormikInput.tsx';
import Container from '@/components/ui/Container/Container.tsx';
import Button from '@/components/ui/Button/Button.tsx';
import { login, whoami } from '@/api/auth.ts';
import routes from '@/constants/routes.ts';
import { loginSchema } from '@/validations/auth.ts';
import { useWhoamiStore } from '@/store/useWhoamiStore.ts';
import Toast from '@/components/ui/Toast/Toast.tsx';
import ButtonLink from '@/components/ui/ButtonLink/ButtonLink.tsx';
import GoogleLogo from '@/assets/Icons/Google-Logo.svg?react';
import MicrosoftLogo from '@/assets/Icons/Microsoft-Logo.svg?react';
import handleResponseErrors from '@/utils/handleResponseErrors.ts';
import GlobalError from '@/components/FormikErrors/GlobalError.tsx';
import usePersistentPreferencesStore from '@/store/usePersistentPreferencesStore.ts';
import { externalLinks } from '@/constants/externalLinks.ts';
import TwoFaModal from '@/pages/Authentication/Login/TwoFaModal/TwoFaModal.tsx';
import { useModalStore } from '@/store/useModalStore.ts';
import { isErrorMatching } from '@/utils/axiosUtil.ts';
import FormikPasswordInput from '@/components/Formik/FormikPasswordInput.tsx';
import LoginRequest = App.Data.Request.LoginRequest;

const Login: React.FC = () => {
	const navigate = useNavigate();
	const { ssoUrls, isPasswordReset } = useWhoamiStore(state => state);
	const [showToast, setShowToast] = useState(false);
	const { returnPath, clearReturnPath } = usePersistentPreferencesStore();
	const { openModal, activeModal } = useModalStore();

	const handleSubmit = async (values: LoginRequest, formikHelpers: FormikHelpers<LoginRequest>) => {
		try {
			await login(values);

			const { redirectUrl } = await whoami();

			if (redirectUrl) {
				// Redirect to Spotnana SAML URL
				window.location.href = redirectUrl;
			} else if (returnPath) {
				// Redirect to returnPath (from localstorage)
				window.location.href = returnPath;
				clearReturnPath();
			} else {
				// Redirect to Spotnana book page
				window.location.href = externalLinks.spotnana.book;
			}
		} catch (error) {
			if (isErrorMatching(error, 'TwoFactorAuthenticationException', 401)) {
				openModal('twoFaModal');
				return;
			}

			handleResponseErrors(error, formikHelpers);
		}
	};

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: loginSchema,
		onSubmit: handleSubmit,
	});

	const { isSubmitting } = formik;

	useEffect(() => {
		isPasswordReset && setShowToast(true);
	}, [isPasswordReset]);

	return (
		<MainLayout>
			<title>Login | Goodwings - Compromise free travel</title>
			<Container>
				<div className={styles.loginContainer}>
					<h1>Login to Goodwings</h1>
					<FormikProvider value={formik}>
						<Form>
							<GlobalError />
							<div>
								<FormikInput name="email" label="Email*" className={styles.formikInput} />
								<FormikPasswordInput name="password" label="Password*" type="password" className={styles.formikInput} />
								<button type="button" className={styles.forgotPassword} onClick={() => navigate(routes.forgotPassword)}>
									Forgot Password?
								</button>
								<Button fullWidth type="submit" color="green" size="large" loading={isSubmitting} disabled={isSubmitting}>
									Sign in
								</Button>
							</div>
						</Form>
					</FormikProvider>
					<p className={styles.or}>Or</p>
					{/* SSO */}
					<ButtonLink fullWidth href={ssoUrls?.google || ''} color="darkGreen" size="large" className={styles.ssoButton}>
						<GoogleLogo /> Continue with Google
					</ButtonLink>
					<ButtonLink fullWidth href={ssoUrls?.microsoft || ''} color="darkGreen" size="large" className={styles.ssoButton}>
						<MicrosoftLogo /> Continue with Microsoft
					</ButtonLink>
				</div>
			</Container>
			<Toast message="Your password is reset and you’re good to go" type="success" show={showToast} />
			{activeModal && <TwoFaModal formik={formik} />}
		</MainLayout>
	);
};

export default Login;
